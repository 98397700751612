import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {MyDatePicker,AutoComp} from '../../components/textField';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './style.css';
import Meta from "../../components/meta";
import CurrencyFormat from "react-currency-format";
import { DialogActions, DialogTitle, IconButton, Tooltip, Zoom } from "@material-ui/core";
import { Add, AddToPhotos, CalendarToday, Comment, Receipt, Visibility, VisibilityOff } from "@material-ui/icons";
import InputMask from 'react-input-mask';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';


const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)
  


export default class Viewsheet extends Component {


  state = {
    token: 'tokenneutro',
    empresa_id: 1,
    permission: localStorage['permission'],
    id:this.props.match.params.id,
    loading:true,
    loadPerc:0,
    sub_nucleos:['Direcao','Producao','Seguranca e testes','Fotografia','Equipamento de Camera','Luz e Infra','Arte','Locacao','Figurino e Make','Producao de Elenco','Elenco','Transporte','Alimentacao','Acompanhamento'],
    sub_nucleos_display: ['Direção','Produçao','Segurança e testes','Fotografia','Equipamento de Câmera','Luz e Infra','Arte','Locação','Figurino e Make','Produção de Elenco','Elenco','Transporte','Alimentação','Acompanhamento'],
    blocos: ['Live Action','Time Interno','Fornecedores','Copias','Ancine'],
    fields:['r_qt','r_diaria','r_valor','comentario','d_qt','d_diaria','d_valor'],
    ativos:0,
    blocosAtivos:[1,0,1,0,0,0],
    sub_nucleos_valor: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sub_nucleos_real: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sub_nucleos_dp: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    lastAtivos:[1,0,0,0,0,0,0,0,0,0,0,0,0,0],
    valor_live_total:0,
    valor_live_real:0,
    valor_time_interno:0,
    valor_time_interno_real:0,
    valor_fornecedores_post:0,
    valor_fornecedores_post_real:0,
    valor_copias:0,
    valor_copias_real:0,
    valor_ancine:0,
    valor_ancine_real:0,
    valor_api:0,
    valor_api_real:0,
    valor_comissao:0,
    valor_comissao_real:0,
    dataOpen:false,
    cnpjOpen:false,
    verbaOpen:false,
    dataAberta:0,
    qualData:0,
    edit:0,
    view:0,
    comments:0,
    fornecedor:[],
    fornecedorNovo:0,
    lineId:0,
    closed:0,
    delivered:0,
    verbaValor:0,
    verbaData:new Date(),
    verbaPj:0,
    emailAcesso:'',
    acessoNegado:true,
    tipoAcesso:0
    
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
 

  async componentDidMount(){
  
    localStorage['neutro']=0;
    this.setPage();
    this.projectInfo();
    
  }

  // PEGA INFORMAÇÕES DO BUDGET

  projectInfo = async () => {

    this.toLoad()
    
      api.get('get/infoProjetos.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
         
          this.setState({

            
            cliente:result.data.projeto[0].cliente,
            nome:result.data.projeto[0].nome,
            closed:result.data.projeto[0].closed/1,
            delivered:result.data.projeto[0].delivered,
            blocosAtivos:result.data.ativos
            
          })

          this.getBudgets();
         
      }})
       

  }

  // LISTA DE ORCAMENTOS


  getBudgets = async () => {
    
    this.toLoad()

    api.get('/get/budgetsProjeto.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
            this.setState({
              budgets:result.data.budgets,
             
              
        })

          this.getLines()
         
          

      }

      
    })
    
      

  }


  // PEGA LINHAS DO PROJETO

  getLines = async() => {
    this.toLoad()
    api.get('get/linesProjeto.php?id='+this.state.id+'&locked=1&view='+this.state.view+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {

        let ativos = 0;

        if(this.state.ativos===0){
         ativos = [1,0,0,0,0,0,0,0,0,0,0,0,0,0];
        }else{
          ativos = this.state.lastAtivos;
        }

      

        if(this.state.view===1){
          this.setState({
            lastAtivos: this.state.ativos
          })
          ativos = [1,1,1,1,1,1,1,1,1,1,1,1,1,1];

        }
      
        


        this.setState({
          live_action:result.data[this.state.blocos[0]],
          time_interno:result.data[this.state.blocos[1]],
          fornecedores_post:result.data[this.state.blocos[2]],
          copias:result.data[this.state.blocos[3]],
          ancine:result.data[this.state.blocos[4]],
          api_list:result.data['api'],
          comissao: result.data['comissao'],
          ativos:ativos
        
        })
               
       
        this.calc_ini()
        this.endLoad()
        
        
        

    }})

  }



    
// ULTIMA PÁGINA VISITADA

setPage = async () => {

  
  api.get('update/setPage.php?page=/projetos&token='+this.state.token);

}


// VIEW LINES

viewLines = () =>{
  
    this.setState({
      view:1-this.state.view,
  })
  setTimeout(() => {
    this.getLines();
  }, 20);
  

}

// VIEW COMENTÁRIOS

viewComentario = () =>{
  
  this.setState({
    comments:1-this.state.comments,
})



}


// CALCULO INICIAL DA PLANILHA APรS O LOADING

calc_ini = () => {
    
  let sub_nucleos_valor = this.state.sub_nucleos_valor;
  let sub_nucleos_dp = this.state.sub_nucleos_dp;
  let sub_nucleos_real = this.state.sub_nucleos_real;
  let valor_live_total = 0;
  let valor_live_dp = 0;
  let valor_live_real = 0;

  for(let i=0;i<sub_nucleos_valor.length;i++){
    sub_nucleos_valor[i] = this.state.live_action[this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.p_qt/1*v.p_diaria/1*v.p_valor/1) , 0 )
    sub_nucleos_dp[i] = this.state.live_action[this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.d_qt/1*v.d_diaria/1*v.d_valor/1) , 0 )
    sub_nucleos_real[i] = this.state.live_action[this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 )
    valor_live_total += sub_nucleos_valor[i];
    valor_live_dp += sub_nucleos_dp[i];
    valor_live_real += sub_nucleos_real[i];
  };

  
  this.setState({
    sub_nucleos_valor: sub_nucleos_valor,
    sub_nucleos_dp: sub_nucleos_dp,
    sub_nucleos_real: sub_nucleos_real,
    valor_live_total: valor_live_total,
    valor_live_dp: valor_live_dp,
    valor_live_real: valor_live_real,
    
  })

  
}



// ABRE NUCLEO

abreNucleo = (w) => {

  this.toLoad()

  let ativos = this.state.ativos;

  ativos[w] = 1-ativos[w];

  this.setState({
    ativos: ativos,
  })

  setTimeout(() => {
    this.endLoad()
  }, 300);
}


// ABRE DATA

abreData = (k,id,data,nucleo,oid) =>{
 
  if(this.state.closed===0){
    
    this.setState({
      dataAberta:data,
      dataOpen:true,
      qualData:id,
      dataGrupo:k,
      dataNucleo:nucleo,
      dataLineObj:oid
    })
  }
  
}



// FORMAT DATE

formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}



// FECHA DATA

fechaDialog = () =>{
  this.setState({
    dataOpen:false,
    cnpjOpen:false,
    mostraCNPJ:false,
    verbaOpen:false,
  })

  this.endLoad()
}

// MUDA DATA

changeData = (e) =>{
  e = this.formatDate(e);
  
  this.setState({
    dataAberta:e
    
  })
}

// SALVA DATA


saveData = () =>{
  
  if(this.state.dataAberta!==0){

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+this.state.dataAberta+'&id='+this.state.qualData+'&sheet=cost&col=dt_prevista&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.setState({
        dataOpen:false
        
      })
  
        let live_action = this.state.live_action;
        live_action[this.state.sub_nucleos[this.state.dataNucleo]][this.state.dataLineObj]['dt_prevista'] = this.state.dataAberta;
        this.setState({
          live_action:live_action
        })

    }})
        
  }else{
    alert('Defina uma data!')
  }
}

// DUPLICAR LINHAS

duplicarLinhas = (w) => {
  if(this.state.closed/1===0){
  api.get('insert/duplicarLinha.php?line_id='+w+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.getLines()
    }})
  }
}


// EDIT

toEdit = (w) => {
  if(this.state.closed/1===0){
    this.setState({
      edit:w
    })
  }
}

// FIELD BLUR

fieldBlur = (w,e,id,tipo,bloco,item) => {

  if(e.target.value!==""){

    let total = 0;

    let live_action = 0;
   
    let ww = 'r';

    if(tipo===0){

      live_action = this.state.live_action;

      live_action[bloco][item][this.state.fields[w]] = e.target.value;
      
      if(w>3){
        ww = 'd';
        total = live_action[bloco][item]['d_qt']/1*live_action[bloco][item]['d_diaria']/1*live_action[bloco][item]['d_valor']/1;
        live_action[bloco][item]['d_total'] = total;
      }else{
        total = live_action[bloco][item]['r_qt']/1*live_action[bloco][item]['r_diaria']/1*live_action[bloco][item]['r_valor']/1;
        live_action[bloco][item]['r_total'] = total;
      }
      

    }

    let fornecedores_post=[];
    if(tipo===2){

      fornecedores_post = this.state.fornecedores_post;

      fornecedores_post[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = fornecedores_post[bloco][item]['r_qt']/1*fornecedores_post[bloco][item]['r_diaria']/1*fornecedores_post[bloco][item]['r_valor']/1;

      fornecedores_post[bloco][item]['r_total'] = total;

    }


  api.get('update/updateLineCost.php?valor='+e.target.value+'&w='+ww+'&col='+this.state.fields[w]+'&id='+id+'&total='+total+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      

  if(tipo===0){
    this.setState({
      edit:0,
      live_action:live_action
    })
  }

  if(tipo===2){
    this.setState({
      edit:0,
      fornecedores_post:fornecedores_post
    })
  }



  setTimeout(() => {
    this.calc_ini()
  }, 10);

  }})



}else{
  
  this.setState({
    edit:0,
    
  })
}
  

}

// SELECIONA PJ

mostraDadosCNPJ = (e) => {

  this.toLoad()
  if(this.state.closed/1===0){
  
    api.get('get/getPj.php?empresa_id='+localStorage['empresa_id']+'&id='+e+'&sheet=users&col_id=id&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
        if(result.data[0]['cnpj']!=='' && result.data[0]['fone']/1>0 && result.data[0]['email']!==''){

          api.get('update/update.php?sheet=cost&id='+this.state.lineId+'&col=pj_id&value='+e+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result2 => {
      
            if (result2.status === 200) {
                
                this.setState({
                  mostraCNPJ:false,
                  cnpjOpen:false,
                  fornecedorAtivo:e
                  
                })
                this.endLoad()
                this.updateLinesCNPJ(this.state.grupoId,this.state.nucleoId,this.state.lineObjId,e)


        
              }
      })
    
    }else{
     
      alert('O usuario precisa ter CNPJ, E-mail e Celular válidos.')
      
        this.setState({
          fornecedor:[]
        }) 
        this.abreCNPJ(this.state.lineId,e,this.state.grupoId,this.state.nucleoId,this.state.lineObjId)
  
      
    }
    

  }});



  }
}



// UPDATE LINES


updateLinesCNPJ = (grupoId,nucleoId,lineObjId,pj_id) => {

  if(this.state.grupoId===0){

    let live_action = this.state.live_action;
    live_action[this.state.sub_nucleos[this.state.nucleoId]][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      live_action:live_action
    })

  }

  if(this.state.grupoId===1){

    let fornecedores_post = this.state.fornecedores_post;
    fornecedores_post['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      fornecedores_post:fornecedores_post
    })

  }

  if(this.state.grupoId===2){

    let api_list = this.state.api_list;
    api_list['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      api_list:api_list
    })

  }

  if(this.state.grupoId===3){

    let comissao = this.state.comissao;
    comissao['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      comissao:comissao
    })

  }

}


// ABRE CNPJ


abreCNPJ = (w,id,grupoId,nucleoId,lineObjId) => {

  if(id==="0"){
 
  this.setState({
    
    cnpjOpen:true,
    lineId:w,
    fornecedorNovo:0,
    grupoId:grupoId,
    nucleoId:nucleoId,
    lineObjId:lineObjId,
   
  })

}else{



  api.get('get/getPj.php?empresa_id='+localStorage['empresa_id']+'&id='+id+'&sheet=users&col_id=id&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      
          this.setState({
            fornecedor:result.data,
            cnpjOpen:true,
            lineId:w,
            mostraCNPJ:true,
            fornecedorNovo:2,
          
          })


}})
}

}


// NOVO PJ
novoCNPJ = (e) => {

    this.setState({
      fornecedor:[],
      fornecedorNovo:1,
      mostraCNPJ:false,
    })
    

    setTimeout(() => {

      let fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":"","email":"","aniversario":"","senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":"","cnpj":"","salario":"0","razao_social":"","inscricao_estadual":"","fone":"","endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0"}]
      
    


        this.setState({
          fornecedor:fornecedor,
          fornecedorNovo:1,
          mostraCNPJ:true,
        })
      
    }, 10);
      

        
        
        

}


// ALTERA DADOS FORNECEDOR

dadosFornecedor = (e,w) =>{


  if(e.target.value!==""){
    let fornecedor = this.state.fornecedor;
    fornecedor[0][w]=e.target.value;

    if(w==='cnpj' || w==='fone' || w==='cpf'){
      fornecedor[0][w] = fornecedor[0][w].replace(/\D/g, "");
    }

    this.setState({
      fornecedor:fornecedor,
    
    })

    }


  }


// UPDATE CADASTRO

updateFornecedor = () => {

  
  if(this.state.fornecedor[0]['email'] && this.state.fornecedor[0]['cnpj']  && this.state.fornecedor[0]['fone'] && this.state.fornecedor[0]['razao_social']){

  
    api.post('update/updateRowCNPJ.php?r='+Math.random(10000),JSON.stringify({ 
      
      sheet:'users',
      line_id:this.state.lineId,
      dados: this.state.fornecedor
    
    })).then(result => {
          
      if (result.status === 200) {
        if(result.data==='update'){
          this.getLines();
          alert('CNPJ já existente no banco. Busque pela razão social ou e-mail.')
        }else{
          let live_action = this.state.live_action;
          live_action[this.state.sub_nucleos[this.state.nucleoId]][this.state.lineObjId]['pj_id'] = this.state.fornecedor[0]['id'];
          this.setState({
            live_action:live_action
          })
          
          this.setState({
            cnpjOpen:false,
          
          })
          this.endLoad()
        }
      }})
    }else{
      alert('CNPJ, Razão Social, Celular e E-mail são obrigatórios.')
    }
}

// CADASTRAR


cadastraFornecedor = () => {


  let fornecedor = this.state.fornecedor;

  if(this.state.closed/1===0){
    
    if(fornecedor[0]['cnpj']!=='' && fornecedor[0]['razao_social']!=='' && fornecedor[0]['email']!=='' && fornecedor[0]['fone']/1>0){
    
      api.get('insert/insert.php?id=1&sheet=users&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        
        fornecedor[0]['id'] = result.data;
        

        api.get('update/update.php?sheet=cost&id='+this.state.lineId+'&col=pj_id&value='+result.data+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
              
          
            this.setState({fornecedor:fornecedor})

              setTimeout(() => {
                this.updateFornecedor();
              }, 50);
              
              setTimeout(() => {
                this.getLines()
              }, 2000);
              

            }})
            
          }})
        

    }else{
      alert('Você precisa preencher Razão Social, CNPJ, E-mail e Celular!')
    }

  }
}


// VIEW SHEET


viewSheet = (w) => {
  window.open('/sheet/'+w)
}



// NAVEGAR TECLADO


handleKeyDown = (e,blocoOriginal,index,col,id,w) => {

  // TAB
 

  if(e.key==='Tab'){

    
    let dataArray = [];

    let bloco = "";
    
    if(w===0 || w===6){
      dataArray = this.state.live_action;
      bloco = this.state.sub_nucleos[blocoOriginal];
    }

    e.preventDefault();
    
    if((col+1)<3){
      
      this.setState({
        edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col+1].split('_')[1]
      })
    }else{
      if(dataArray[bloco][index+1]){
        this.setState({
          edit: dataArray[bloco][index+1]['id']+'_'+this.state.fields[0].split('_')[1]
        })
      }
    }



    if(e.target.value!==""){

      dataArray[bloco][index][this.state.fields[col]]=e.target.value;
      let total = 0;
      if(w<6){
      total = dataArray[bloco][index]['r_qt']/1*dataArray[bloco][index]['r_diaria']/1*dataArray[bloco][index]['r_valor']/1;
      dataArray[bloco][index]['r_total'] = total;
      }else{
    
        total = dataArray[bloco][index]['p_qt']/1*dataArray[bloco][index]['p_diaria']/1*dataArray[bloco][index]['p_valor']/1;
        dataArray[bloco][index]['p_total'] = total;
    
      }
    
    
      api.get('update/updateLineCost.php?valor='+e.target.value+'&w=r&col='+this.state.fields[col]+'&id='+id+'&total='+total+'&r='+Math.random(10000)).then(result => {
          
        if (result.status === 200) {
    
               this.setState({
                live_action:dataArray
              })
    
        }})
      }

      
    
    
  }


 
  if(e.keyCode >=37 && e.keyCode <=40){

    let dataArray = [];

    let bloco = "";

    if(w===0 || w===6){
      dataArray = this.state.live_action;
      bloco = this.state.sub_nucleos[blocoOriginal];
    }

 
    if(w===2){
      dataArray = this.state.fornecedores_post;
      bloco='unico';
    }

    if(w===3){
      dataArray = this.state.copias;
      bloco='unico';
    }

    if(w===4){
      dataArray = this.state.ancine;
      bloco='unico';
    }

    if(w===5){
      dataArray = this.state.api_list;
      bloco='unico';
    }

    if(w===7){
      dataArray = this.state.comissao;
      bloco='unico';
    }



    

    
  

  if(e.keyCode === 40){
   //baixo

    if(dataArray[bloco][index+1]){
      this.setState({
        edit: dataArray[bloco][index+1]['id']+'_'+this.state.fields[col].split('_')[1]
      })
    }else{
        if(dataArray[bloco] && blocoOriginal!=='unico'){
          bloco = this.state.sub_nucleos[blocoOriginal+1];
          this.setState({
          edit: dataArray[bloco][0]['id']+'_'+this.state.fields[col].split('_')[1]
        })
        }
      }
    }

  if(e.keyCode === 38){
    // cima

    if(dataArray[bloco][index-1]){
      this.setState({
        edit: dataArray[bloco][index-1]['id']+'_'+this.state.fields[col].split('_')[1]
      })
    }else{
      if(dataArray[this.state.sub_nucleos[blocoOriginal-1]] && blocoOriginal!=='unico'){
        
        index = dataArray[this.state.sub_nucleos[blocoOriginal-1]].length-1;
        bloco = this.state.sub_nucleos[blocoOriginal-1];
        this.setState({
          edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col].split('_')[1]
        })
      }
    }

      
  }

  if(e.keyCode === 39){
    if((col+1)<3){
      this.setState({
        edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col+1].split('_')[1]
      })
    }
  }

  if(e.keyCode === 37){
    if((col-1)>=0){
      this.setState({
        edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col-1].split('_')[1]
      })
    }
     
      
  }

  if(e.target.value!==""){

    dataArray[bloco][index][this.state.fields[col]]=e.target.value;
    let total = 0;
    if(w<6){
    total = dataArray[bloco][index]['r_qt']/1*dataArray[bloco][index]['r_diaria']/1*dataArray[bloco][index]['r_valor']/1;
    dataArray[bloco][index]['r_total'] = total;
    }else{
  
      total = dataArray[bloco][index]['p_qt']/1*dataArray[bloco][index]['p_diaria']/1*dataArray[bloco][index]['p_valor']/1;
      dataArray[bloco][index]['p_total'] = total;
  
    }
  
  
    api.get('update/updateLineCost.php?valor='+e.target.value+'&w=r&col='+this.state.fields[col]+'&id='+id+'&total='+total+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
  
             this.setState({
              live_action:dataArray
            })
  
      }})
    }

  
}


  
}



// DELIVERED

delivered = () => {
  
  if(this.state.delivered===0){
    
    api.get('update/update.php?sheet=projeto&id='+this.state.id+'&col=delivered&value=1&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
        this.setState({delivered:1})

        }})
  }
}

// OPEN DIALOG VERBA

openVerba = () => {

  this.setState({
    verbaOpen:true
  })

}

// UPDATE VERBA

upVerba = (e,w) => {

  if(w==='data'){
    this.setState({verbaData:e})
  }

  if(w==='valor'){
    this.setState({verbaValor:e.target.value})
  }

  if(w==='pj'){
    this.setState({verbaValor:e})
  }

}

// SOLICITA VERBA

solicitarVerba = () => {



}

// VOID

void = () => {



}


// UPDATE ACESSO 

setEmailAcesso = (e) => {
  
  this.setState({emailAcesso:e.target.value})
}


// ACESSAR

acessar = () => {

  api.get('get/acessoSheet.php?email='+this.state.emailAcesso+'&projeto_id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
       
      if(result.data.length>0){

        if(result.data[0]['projeto_id']===this.state.id){
            this.setState({
              acessoNegado:false,
              tipoAcesso:result.data[0]['tipo']
            })
        }
      }
      
      

      }})


}


  render(){

  return (

    
    <ThemeProvider theme={theme}>
       
<Meta title="Projetos"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
      <Grid container spacing={3}>
            
            <Grid item xs={10}><div className="titulo"><span className="color_1">{this.state.id+' '}</span>{this.state.cliente ? this.state.cliente+' '+this.state.nome : ''}</div></Grid>
            
            <Grid item xs={2} >
            <Grid container justifyContent="space-between">
            <Tooltip title="Esconder Linhas" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.viewLines()} color={this.state.view===0 ? "inherit" : "secondary"} target="_blank">{this.state.view===0 ? <Visibility/> : <VisibilityOff/>}</IconButton></Tooltip>
            <Tooltip title="Ver Comentários" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.viewComentario()} color={this.state.comments===0 ? "inherit" : "secondary"}  target="_blank"><Comment/></IconButton></Tooltip>
            
            </Grid>
            


            
            </Grid>
             
          </Grid>
          

          <div className="mt10 mb5">
                  <Grid container spacing={0} className={"bgcolor_8 mt20 br5"}>
                        <Grid item xs={5} className="micro-titulo"><div className="mt5 pd15">Budgets</div></Grid>
                        <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Valor Total</div></Grid>
                        <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Live Action</div></Grid>
                        <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Fornecedores</div></Grid>
                  </Grid>
          </div>


          {this.state.budgets ? this.state.budgets.map((budgets,objectID)=> (
          <div onClick={(w)=>this.viewSheet(budgets.id)} key={objectID} className="mb1 line">
                  <Grid container spacing={0} className={"bgcolor_7 br5"}>
                      
                        <Grid item xs={5} className=""><div className="mt5 pd15"><span className="color_1">{budgets.id}</span> {this.state.nome+' - '+budgets.nome}</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">{<CurrencyFormat value={budgets.valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">{<CurrencyFormat value={budgets.live_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">{<CurrencyFormat value={budgets.freelas_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                  
                  </Grid>

          </div>

          )) : ''}


    
        <div className="mt10 mb5">
                  <Grid container spacing={0} className={"bgcolor_1 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">Live Action</div></Grid>
                        <Grid item xs className=""><div className="mt5 pd15 color_9">Orçado</div></Grid>
                        <Grid item xs className=""><div className="mt5 pd15">{<CurrencyFormat value={this.state.valor_live_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs className=""><div className="mt5 pd15 color_9">Previsto</div></Grid>
                        <Grid item xs className=""><div className="mt5 pd15">{<CurrencyFormat value={this.state.valor_live_dp}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs className=""><div className="mt5 pd15 color_9">Realizado</div></Grid>
                        <Grid item xs className=""><div className="mt5 pd15">{<CurrencyFormat value={this.state.valor_live_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 mt20"></div></Grid>
                  </Grid>
          </div>

          {this.state.sub_nucleos_display.map((sub,objectID)=> (
            <div key={objectID}>
          <Grid container spacing={0} onClick={(w)=>this.abreNucleo(objectID)} className={"bgcolor_1 br5 mb1 line"}>
                      
                <Grid item xs={4} className="">
                      <div className="mt5 pd15 l">{sub}</div>
                </Grid>
                
                <Grid item xs className="">
                      <div className="mt5 pd15 l"><CurrencyFormat value={this.state.sub_nucleos_valor[objectID]}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                </Grid>

                <Grid item xs className="">
                      <div className="mt5 pd15 l"><CurrencyFormat value={this.state.sub_nucleos_dp[objectID]}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                </Grid>

                <Grid item xs={1} className="">
                      <div className="mt5 pd15 l"><CurrencyFormat value={this.state.sub_nucleos_real[objectID]}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                </Grid>
                <Grid item xs={2} className=""></Grid>
          </Grid>

          
          
            {this.state.ativos[objectID]===1 ? this.state.live_action[this.state.sub_nucleos[objectID]].map((lines,objectID2)=>(
            
          <Grid key={objectID2} container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                      
                        <Grid item xs={2} className="">
                        <div className="ff-ro l">{lines.nome}</div></Grid>
                      
                        {this.state.comments===0 ? 
                        
                        <Grid container spacing={0} item xs={8}>
                           
                            <Grid item xs className="bgcolor_10 tc"><div className="ff-ro">{lines.p_qt}</div></Grid>
                            <Grid item xs className="bgcolor_10 tc"><div className="ff-ro">{lines.p_diaria}</div></Grid>
                            <Grid item xs className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.p_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            <Grid item xs className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.p_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            
                            <Grid item xs className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_d_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_d_qt' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(4,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,0,lines.id,0)} placeholder={lines.d_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.d_qt}</div>}</Grid>
                            <Grid item xs className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_d_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_d_diaria' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(5,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,1,lines.id,0)} placeholder={lines.d_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.d_diaria}</div>}</Grid>
                            <Grid item xs className="bgcolor_7" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_d_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_d_valor' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(6,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,2,lines.id,0)} placeholder={lines.d_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff"><CurrencyFormat value={lines.d_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                            <Grid item xs className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.d_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                          
                            <Grid item xs className="bgcolor_10 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,0,lines.id,0)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_qt}</div>}</Grid>
                            <Grid item xs className="bgcolor_10 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,1,lines.id,0)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_diaria}</div>}</Grid>
                            <Grid item xs className="bgcolor_10" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,2,lines.id,0)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff"><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                            <Grid item xs className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                          
                        </Grid>

                          : 

                          <Grid item xs={8} className="bgcolor_10" onClick={(w)=>this.toEdit(lines.id+'_com')}>{this.state.edit === lines.id+'_com' ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(3,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} placeholder={lines.comentario.replace('"','')} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.comentario.replace('"','')}</div>}</Grid>

                          }

                        <Grid item xs={2} className="icons-line bgcolor_7">
                            <Grid container spacing={0} justifyContent="space-between">
                            
                            <Tooltip title="Duplicar linha" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={(w)=>this.duplicarLinhas(lines.id)} target="_blank"><AddToPhotos/></IconButton></Tooltip>
                            <Tooltip title="Dados para pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={lines.pj_id>0 ? "secondary" : 'inherit'} onClick={(w,pj)=>this.abreCNPJ(lines.id,lines.pj_id,0,objectID,objectID2)} target="_blank"><Receipt/></IconButton></Tooltip>
                            <Tooltip title="Data de pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton color={lines.dt_prevista ? "secondary" : 'inherit'} size="small" onClick={(w)=>this.abreData(0,lines.id,lines.dt_prevista ,objectID,objectID2)} target="_blank"><CalendarToday/></IconButton></Tooltip>
                            
                        
                        </Grid>
                        </Grid>
                    
                     
         </Grid>
            )) : ''}



            

                
         </div>


))}


{this.state.blocosAtivos[0]['a_fornecedores_post']==='0' && this.state.tipoAcesso/1===1 ? 
              <div className="mt10 mb5">
                <Grid container spacing={0} className={"bgcolor_3 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">Fornecedores</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.valor_fornecedores_post}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.valor_fornecedores_post_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                  </Grid>


                  {this.state.fornecedores_post ? this.state.fornecedores_post['unico'].map((lines,objectID4)=>(
            
              <Grid key={objectID4} container spacing={0} className={this.state.temperature ? lines.o_total/1 >= lines.r_total/1 ? "cor_perf_1 line-budget mb1 br5":"cor_perf_2 line-budget mb1 br5" : "bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="" onClick={(w)=>this.toEdit(lines.id+'_nome',lines.empresa_id)}>
                         
                          {this.state.edit === lines.id+'_nome' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(7,e,lines.id,2,'unico',objectID4)} defaultValue={lines.nome} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.nome}</div>}
                        
                          </Grid>
                        
  
                          {this.state.comments===0 ? 
                        <Grid item xs={4}>
                           <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                              <Grid item xs={3} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>
                        </Grid>
                         : 

                         <Grid item xs={4} className="bgcolor_10" onClick={(w)=>this.toEdit(lines.id+'_com',lines.empresa_id)}>{this.state.edit === lines.id+'_com' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(3,e,lines.id,2,'unico',objectID4)} defaultValue={lines.comentario} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.comentario.replace('"','')}</div>}</Grid>
                        
                        }

                        <Grid item xs={1} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,2,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,0,lines.id,2)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.r_qt}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,2,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,1,lines.id,2)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.r_diaria}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.id+'_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,2,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,2,lines.id,2)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10"></Grid>
                      
                       
                  </Grid>
                      )) : ''}
                  </div>
                : ''}




         

          


          

         


              <Dialog
                open={this.state.cnpjOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>{"Dados do fornecedor"}</DialogTitle>
                <DialogContent>
                {this.state.closed/1===0 ?
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                  <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="pjs" label="CNPJ, Nome ou E-mail" change={(e)=>this.mostraDadosCNPJ(e)} blur={(e)=>this.void(e)}/>
                </Grid>
               
                  <Grid item xs={1} className="tr">
                  
                    <IconButton size="large" onClick={()=>this.novoCNPJ('Novo')}><Add fontSize="large"/></IconButton>
                  </Grid>

                  </Grid>
                  : ''}
                {this.state.mostraCNPJ ? 
                <div className="mt20">
                 
                  {this.state.fornecedor.map((fornecedor,objectId) => (

                    
                <Grid key={objectId} container spacing={1}>
                  
                  {this.state.fornecedorNovo!==1 ? '' : <div className="wp100 pd10 mt20 mb20 mini-titulo">{'Cadastrar Novo fornecedor '} </div>}
                <Grid item xs={4}>
                  <TextField autoFocus={true} autoComplete="off" variant="outlined" label="Nome" fullWidth={true}  defaultValue={fornecedor.nome} placeholder={"Nome"} InputProps={this.state.fornecedorNovo === 2 ? {readOnly: true} : {}} onBlur={(e)=>this.dadosFornecedor(e,'nome')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Razão Social" fullWidth={true}  defaultValue={fornecedor.razao_social} placeholder="Razão Social" InputProps={this.state.fornecedorNovo === 2 ? {readOnly: true} : {}} onBlur={(e)=>this.dadosFornecedor(e,'razao_social')}/>
                </Grid>

                <Grid item xs={4}>
                  
                <InputMask
                      mask="99.999.999/9999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cnpj} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cnpj')}
                      >
                      {() =>   <TextField variant="outlined" label="CNPJ" kind="cnpj" fullWidth={true}  placeholder={"CNPJ"} />}
                  </InputMask>
                  
                  
                  
                   </Grid>

                   <Grid item xs={4}>
                  <TextField variant="outlined" label="E-mail" fullWidth={true}  defaultValue={fornecedor.email} placeholder={"E-Mail"} onBlur={(e)=>this.dadosFornecedor(e,'email')}/>
                </Grid>

                <Grid item xs={4}>
                  <InputMask
                      mask="999.999.999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cpf} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cpf')}
                      >
                      {() =>   <TextField variant="outlined" label="CPF" fullWidth={true}  placeholder={"CPF"} />}
                  </InputMask>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Celular" fullWidth={true}  defaultValue={fornecedor.fone} placeholder="Celular com ddd" onBlur={(e)=>this.dadosFornecedor(e,'fone')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="RG" fullWidth={true}  defaultValue={fornecedor.rg} placeholder="RG" onBlur={(e)=>this.dadosFornecedor(e,'rg')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Data de Nascimento" fullWidth={true}  defaultValue={fornecedor.data_nascimento} placeholder="Data de Nascimento" onBlur={(e)=>this.dadosFornecedor(e,'data_nascimento')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="PIX" fullWidth={true}  defaultValue={fornecedor.pix} placeholder="PIX" onBlur={(e)=>this.dadosFornecedor(e,'pix')}/>
                </Grid>


                <Grid item xs={4}>
                  <TextField variant="outlined" label="Endereço" fullWidth={true}  defaultValue={fornecedor.endereco} placeholder="Endereço" onBlur={(e)=>this.dadosFornecedor(e,'endereco')}/>
                </Grid>

                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Cidade" fullWidth={true}  defaultValue={fornecedor.cidade} placeholder="Cidade" onBlur={(e)=>this.dadosFornecedor(e,'cidade')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="DRT" fullWidth={true}  defaultValue={fornecedor.drt} placeholder="DRT" onBlur={(e)=>this.dadosFornecedor(e,'drt')}/>
                </Grid>

               
                

                


                </Grid>
                ))}
                </div>
                : ''}
                
                    {this.state.fornecedorNovo === 1 ? 
                <Grid container>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_2 mt20" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_1 mt20 mb20" onClick={()=>this.cadastraFornecedor()} color="secondary">
                    Cadastrar
                  </div>
                  </Grid>
                </Grid>

                :''}



                {this.state.fornecedorNovo === 2 ? 
                <Grid container>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_2 mt20" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_1 mt20 mb20" onClick={()=>this.updateFornecedor()} color="secondary">
                    Atualizar dados
                  </div>
                  </Grid>
                </Grid>

                :''}


                
               
                </DialogContent>
                
              </Dialog>

              <Dialog
                open={this.state.dataOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Data combinada de pagamento"}</DialogTitle>
                <DialogContent>

                    <MyDatePicker onChange={(e)=>this.changeData(e)} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data combinada" startDate={this.state.dataAberta}/>
                  
                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.saveData()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
              </Dialog>





              <Dialog
                open={this.state.verbaOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Solicitar verba</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <Grid item xs={3}>

                  <CurrencyTextField variant="outlined" label="Valor" fullWidth={true}  placeholder="Valor" onBlur={(e)=>this.upVerba(e,'valor')} currencySymbol="R$" outputformat="number"/>
                
                  </Grid>

                  <Grid item xs={3}>

                    <MyDatePicker onChange={(e)=>this.upVerba(e,'data')} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data combinada" startDate={this.state.dataAberta}/>
                  
                  </Grid>

                  <Grid item xs={4}>
                    
                  <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="pjs" label="CNPJ, Nome ou E-mail" blur={()=>this.void()} change={(e)=>this.upVerba(e,'pj')}/>
               
                  </Grid>

                  <Grid item xs={2}>
                  <div className="bt large bgcolor_1" onClick={()=>this.solicitarVerba()} color="secondary">
                    Solicitar
                  </div>
                  
                  </Grid>


                </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>




          

          


          </div>
          <div>
            {this.state.acessoNegado===true ? 
          <div className="full bgcolor_6">
            <div className="center w50">
              
              <Grid container spacing={1}>
                    
                    <Grid item md={12} lg={9}>
                      <TextField variant="outlined" fullWidth={true}  label="E-mail" onBlur={(e)=>this.setEmailAcesso(e)} ></TextField>
                    </Grid>

                    <Grid item md={12} lg={3}>
                    <div className="bt large bgcolor_1" onClick={()=>this.acessar()} color="secondary">
                    Entrar
                  </div>
                  </Grid>

              </Grid>
             </div>
          </div>
          : ''}
</div>

    </ThemeProvider>
  )


}
}
  