import React, { Component } from "react";
import {CircularProgress, Grid,IconButton,Switch, Tooltip, Zoom} from '@material-ui/core';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import {TextField } from "@material-ui/core";
import ReorderIcon from '@material-ui/icons/Reorder';
import Meta from "../../components/meta"; 
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { AutoCompFree, MyDatePicker,MyDateTimePicker,MySelect, TextInput } from "../../components/textField";
import { Redirect } from "react-router";
import { CalendarToday, InsertDriveFile} from "@material-ui/icons";
import CurrencyFormat from "react-currency-format";

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "11px",
          backgroundColor:'#666'
    }},
    MuiIconButton: {
      root: {
          color:'#ddd',
        '&:hover': {
          color: teal[100]
        }
      }
    }
  }

},

  
)





export default class Decupagem extends Component {

  

  state = {
    token: localStorage.getItem("tokens"),
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    usuario_id: localStorage['usuario_id'],
    nome: localStorage['nome'],
    id:this.props.match.params.id,
    labelBt1:'Enviar',
    labelBt2:'Enviar',
    labelBt3:'Gerar briefing para post',
    loading:true, 
    orcarLiquor:false,
    estimate:false,
    liquor:[],
    valorLiquor:0,
    editText:0,
    imgs:[0,0,0,0,0,0,0,0,0,0,0,0,0],
    itens:['Tratamento','Mesa de compras','Trilha composta','Trilha branca','Locução','Mixagem'],
    itensPost:['Pós básica','Hard Motion','3D/VFX','Direção de arte','Matte painting','Animatic','Finalizador exclusivo','HUB Exclusivo','Final de semana','Base limpa','Collect','Compra de HD','Cópias no custo','Cópias à parte','Select do material bruto','Frames para divulgação','Versão divulgação','Equipe dupla','Libras','Audio descrição','Legendas','Closed caption embed','Closed caption separado','Arquivo Srt'],
    info:[0,0,0,0,0,0,0],
    infoPost:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    periodo:[{'label':'3 meses','value':3},{'label':'6 meses','value':6},{'label':'1 ano','value':12},{'label':'1,5 ano','value':18},{'label':'2 anos','value':24}],
    condecine:[{'label':'Nenhum','value':'Nenhum'},{'label':'Todas as mídias','value':'Todas as mídias'},{'label':'TV Aberta','value':'TV Aberta'},{'label':'TV Assinatura','value':'TV Assinatura'},{'label':'Cinema','value':'Cinema'},{'label':'Web','value':'Web'},{'label':'Vídeos internos','value':'Vídeos internos'}],
    liquor_active:0,
  }

  // LOADING

  toLoad = () => {
    
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }


  


  async componentDidMount(){
    
    
    this.decupagemInfo();
    
    this.setPage();

    this.update()
    

  }



  // SALVA PAGINA

  setPage = async () => {

    
    api.get('update/setPage.php?page=/budgets&token='+this.state.token);
    
  }


  
// PEGA INFOS DECUPAGEM

decupagemInfo = () => {
    
 
  this.toLoad();
  
  api.get('get/decupagem.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
      
      let gifs = result.data.decupagem[0]['gifs'].split(',');

      if(gifs[0]==='0'){
        gifs = [0,0,0,0,0,0,0,0,0,0,0,0,0];
      }
      
     
        this.setState({

          projeto_id:result.data.budget[0].projeto_id,
          versao:result.data.budget[0].nome,
          diretor:result.data.diretor,
          diretor_id:result.data.projeto[0].diretor_id,
          projeto:result.data.projeto[0].nome,
          cliente:result.data.projeto[0].cliente,
          agencia:result.data.projeto[0].agencia,
          imgs:gifs,
          decupagem:result.data.decupagem[0],
          entregas:result.data.entregas,
          a_live:result.data.a_live,
          office:result.data.office,
          orcarLiquor:result.data.liquor,
          liquor_active:result.data.liquor,
          coordenadores:result.data.coordenadores,
          coordenador:result.data.coordenador
          
        })

        

        this.getEntregas();
        
        if(result.data.decupagem[0]){
          
          if(result.data.decupagem[0].infos_main.length>1){
            this.setState({
              info:result.data.decupagem[0].infos_main.split(','),
              
            })
          }

          if(result.data.decupagem[0].infos_post.length>1){
            this.setState({
              infoPost:result.data.decupagem[0].infos_post.split(','),
            })
          }
          
          
          

        }

    
        
        this.endLoad();
        
}})
}

// PEGA INFOS ENTREGAS

getEntregas = () =>{

  this.setState({
    qtCopias:'Calculando...',
  })
  api.get('get/decupagemEntregas.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
        this.setState({
          entregas:result.data.entregas,
          liquor:result.data.liquor
        })

        this.somaEntregas()
    }})
}

// SOMA ENTREGAS

somaEntregas = () => {

  
  let qt = 0
  let qtText = '';
  let entregas = this.state.entregas;
  let tl = 0;
  let timelines15 = 0;
  let timelines30 = 0;
  let timelines45 = 0;
  let timelines60 = 0;


  for(let i=0;i<entregas.length;i++){

    entregas[i]['reducoes']=entregas[i]['reducoes']/1+1;
    qt+=entregas[i]['qt']*entregas[i]['reducoes']*entregas[i]['formatos'];
    
    if(entregas[i]['segundos']/1===15){
      timelines15+=1;
      tl+=1;
    }

    if(entregas[i]['segundos']/1===30){
      timelines30+=1;
      tl+=1;
    }

    if(entregas[i]['segundos']/1===45){
      timelines45+=1;
      tl+=1;
    }

    if(entregas[i]['segundos']/1===60){
      timelines60+=1;
      tl+=1;
    }
  }

  

  
  qtText = qt+ ' cópias';
  this.setState({

    tl:tl,
    qtCopias:qtText,
    qtNumCopias:qt,
    timelines15:timelines15,
    timelines30:timelines30,
    timelines45:timelines45,
    timelines60:timelines60
    
  })

  this.somaLiquor()

}

somaLiquor = () => {

  let estimate=true;
  let valorLiquor = 0;

  // TIMELINES

  valorLiquor += this.state.timelines60 * this.state.liquor[0]['valor'];
  valorLiquor += this.state.timelines30 * this.state.liquor[1]['valor'];
  valorLiquor += this.state.timelines45 * this.state.liquor[2]['valor'];
  valorLiquor += this.state.timelines15 * this.state.liquor[3]['valor'];

  valorLiquor = valorLiquor * (1-this.state.tl/20);

  // COPIAS

  if(this.state.infoPost[12]/1===1){
  valorLiquor += this.state.qtNumCopias * this.state.liquor[14]['valor'];
  }

  // FINALIZADOR

  if(this.state.infoPost[6]/1===1){
    valorLiquor += this.state.liquor[6]['valor']/1;
  }

  if(valorLiquor>70000){
    valorLiquor += 4000;
  }


  // HUB

  if(this.state.infoPost[7]/1===1){
    valorLiquor = valorLiquor*1.3;
  }

  
    // FINAL DE SEMANA

  if(this.state.infoPost[8]/1===1){
    valorLiquor = valorLiquor*1.2;
  }

  // BASE LIMPA

  if(this.state.infoPost[9]/1===1){
    valorLiquor += this.state.liquor[7]['valor']/1;
  }

  // COLLECT

  if(this.state.infoPost[10]/1===1){
    valorLiquor += this.state.liquor[8]['valor']/1;
  }

  // COMPRA DE HD

  if(this.state.infoPost[11]/1===1){
    valorLiquor += this.state.liquor[13]['valor']/1;
  }

  // SELECT

  if(this.state.infoPost[14]/1===1){
    valorLiquor += this.state.liquor[15]['valor']/1;
  }

  // EQUIPE DUPLA

  if(this.state.infoPost[17]/1===1){
    valorLiquor = valorLiquor*1.4;
  }

  // LIBRAS

  if(this.state.infoPost[18]/1===1){
    valorLiquor += this.state.tl*this.state.liquor[16]['valor']/1;
  }

  // AUDIO

  if(this.state.infoPost[19]/1===1){
    valorLiquor += this.state.tl*this.state.liquor[17]['valor']/1;
  }

  // LEGENDAS

  if(this.state.infoPost[20]/1===1){
    valorLiquor += this.state.tl*this.state.liquor[9]['valor']/1;
  }

  // CLOSED

  if(this.state.infoPost[21]/1===1){
    valorLiquor += this.state.tl*this.state.liquor[10]['valor']/1;
  }

  // CLOSED SEPARADO

  if(this.state.infoPost[22]/1===1){
    valorLiquor += this.state.tl*this.state.liquor[11]['valor']/1;
  }

  // SRT

  if(this.state.infoPost[23]/1===1){
    valorLiquor += this.state.tl*this.state.liquor[12]['valor']/1;
  }
  

  // ZERA
  
  
  if(this.state.infoPost[1]/1===1 || this.state.infoPost[2]/1===1 || this.state.infoPost[3]/1===1 || this.state.infoPost[4]/1===1 || this.state.infoPost[5]/1===1){
    estimate=false
    }
  
  this.setState({
    valorLiquor:valorLiquor,
    estimate:estimate
  })
}

  // CLICA PARA EDITAR O NOME

  editNomeVersao = () => {

    this.setState({
      editNome:1,
    })

   }

   // EDITA O NOME DA VERSÃO

   setNomeVersao = (e) => {

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e.target.value+'&id='+this.state.id+'&sheet=budget&col=nome&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

            if(e.target.value!==""){
              this.setState({
                editNome:0,
                versao: e.target.value
              })
            }else{
              this.setState({
                editNome:0,
              })
            }
      }
    })
   }

   // MARCA GIF

   marcaGif = (w) => {
     let imgs = this.state.imgs;
      
        imgs[w]=1-imgs[w]/1;
     
        api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+imgs.join(',')+'&id='+this.state.decupagem['id']+'&sheet=decupagem&col=gifs&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {

            this.setState({
              imgs:imgs
            })

          }})
     
   }

   // MUDA SWITCH

   mudaSwitch = (w,t) => {
     
    this.toLoad()
    let coluna='infos_main';
    let info = this.state.info;
     if (t===1){
        coluna='infos_post';
        info = this.state.infoPost;
     }

     info[w] = 1-info[w]/1;
    
     api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+info.join(',')+'&id='+this.state.decupagem.id+'&sheet=decupagem&col='+coluna+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        if (t===0){
          this.setState({
            info:info
          })
        }else{
          this.setState({
            infoPost:info
          })
        }
          this.endLoad()

          this.somaLiquor()
      }})



  }


   // MUDA SWITCH

   mudaSwitchEntregas = (id,n) => {
     
    let entregas = this.state.entregas;
    let arrP = 0;
    for(let i=0;i<entregas.length;i++){
      if(entregas[i]['id']===id){
         arrP = i;
      }
    }

    entregas[arrP][n] = 1-entregas[arrP][n];
   
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+entregas[arrP][n]+'&id='+entregas[arrP]['id']+'&sheet=entregas&col='+n+'&r='+Math.random(10000)).then(result => {
       
     if (result.status === 200) {

         this.setState({
          entregas:entregas
         })

     }})

     this.somaEntregas()
  }

  // MUDA SWITCH VERSION

  mudaSwitchVersion = (id,n) => {
     
    let versoes = this.state.versoes;
    let arrP = 0;
    for(let i=0;i<versoes.length;i++){
      if(versoes[i]['id']===id){
         arrP = i;
      }
    }

    versoes[arrP][n] = 1-versoes[arrP][n];
   
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+versoes[arrP][n]+'&id='+versoes[arrP]['id']+'&sheet=entregas&col='+n+'&r='+Math.random(10000)).then(result => {
       
     if (result.status === 200) {

         this.setState({
          versoes:versoes
         })

     }})
     this.somaEntregas()
  }



// INSERE NOVA VERSAO

newVersao = (w) => {
  this.toLoad()
  api.get('insert/newEntrega.php?entrega_id='+w+'&budget_id='+this.state.id+'&r='+Math.random(10000)).then(result => {
    if (result.status === 200) {

        this.decupagemInfo()

    }})

}



// INSERE NOVA ENTREGA


novaEntrega = () => {
  this.toLoad()
  api.get('insert/newEntrega.php?budget_id='+this.state.id+'&r='+Math.random(10000)).then(result => {
    if (result.status === 200) {

        this.decupagemInfo()

    }})

}

   // FORMAT DATE

  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

addZero = (i) =>  {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

// FORMAT DATE

formatDateH = (date) => {


  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = this.addZero(d.getHours()),
      min = this.addZero(d.getMinutes())
      ;

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-') + ' '+hour+':'+min;
}

   // FORMAT DATE

   formatDate2 = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
        
        ;

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}

// FORMAT DATE

formatDate2H = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = this.addZero(d.getHours()),
      min = this.addZero(d.getMinutes())

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('/') + ' '+hour+':'+min;
}


  // ESCREVE TEXTFIELD

  upDecupagem = (e,w) => {
    if(w==='prazo_orc'){
      let dt = new Date(e)
      
      dt = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000);
      
      e = dt.toISOString()
      e = e.replace('T',' ');
      e = e.split('.')[0]
      
    }
    let decupagem = this.state.decupagem;
    decupagem[w] = e;
    this.setState({
      decupagem:decupagem
    })
  }

   // MUDA DECUPAGEM

   changeDecupagem = (e,w) => {
   
    let decupagem = this.state.decupagem;

    if(w==="budget"){e=e.replace(',','')}
   
    if(w==="prazo_ent"){e = this.formatDate(e)}

    if(w==='prazo_orc'){
      let dt = new Date(e)
      
      dt = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000);
      
      e = dt.toISOString()
      e = e.replace('T',' ');
      e = e.split('.')[0]
      
    }
    
   if(w==="infos"){
    e = e.split(/\r?\n/)
    e = e.join('<br>')
    
   }
    decupagem[w] = e;
  
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e+'&id='+this.state.decupagem.id+'&sheet=decupagem&col='+w+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

          this.setState({
            decupagem:decupagem
          })

      }})

   }


   // CHANGE COORDENADOR

   changeCoordenador = (e) => {
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e+'&id='+this.state.projeto_id+'&sheet=projeto&col=block&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

          this.setState({
            coordenador:e
          })

      }})
   }
   // MUDA ENTREGAS 

   changeEntregas = (e,w,id,o) => {
    
    this.toLoad()
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e+'&id='+id+'&sheet=entregas&col='+w+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        this.endLoad()

      }})

      if(w==='qt'){
        let entregas = this.state.entregas;
        entregas[o]['qt'] = e;

        this.setState({
          entregas:entregas
        })
        this.somaEntregas()
      }
      

   }



// ENVIAR

   sendEmail = (w) => {

    this.toLoad();

    // ATENDIMENTO
    if(w===0){


    let imgs = this.state.imgs;
    let itens = this.state.itens;
    let itensPost = this.state.itensPost;
    let info = this.state.info;
    let infoPost = this.state.infoPost;
    let decupagem = this.state.decupagem;
   
    
    let title = "Pedido de orçamento | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto + ' | ' + this.state.diretor;
    
    let msg = '';
    
    if(this.state.nome){
      msg += '<b>Atendimento:</b> '+this.state.nome+'<br><br>';
    }

    if(this.state.diretor){
      msg += '<b>Diretor:</b> '+this.state.diretor+'<br><br>';
    }
    if(this.state.agencia){
      msg += '<b>Agência:</b> '+this.state.agencia+'<br><br>';
    }
    
    msg += '<b>Entrega do orçamento:</b> '+this.formatDate2H(decupagem['prazo_orc'])+'<br><br>';
    msg += '<b>Entrega do projeto:</b> '+this.formatDate2(decupagem['prazo_ent'])+'<br><br>';
    msg += '<b>Target do orçamento</b> R$ '+decupagem['budget']+'<br><br>';

    for(let i=0;i<imgs.length;i++){
      if(imgs[i]/1===1){
        msg += '<img src="https://treatments.run/landscape/img/gif/'+(i+1)+'.gif"></img>';
      }
    }

    msg += '<br><br><b> Como chegou o orçamento?</b><br>';
    
    msg += decupagem['origem'];

    msg += '<br><br> Infos:</b><br>';
    
    
    for(let i=0;i<itens.length;i++){
      
       
      msg += '<br><b>'+itens[i]+':</b> ';
        if(info[i]/1===1){
          msg += 'Sim';
          }else{
            msg += 'Não';
          }
    
    }


    
    msg += '<br><b>Concorrência</b> '+decupagem['concorrencia']+'<br><br>';
    msg += '<b>Condição de pagamento</b> '+decupagem['condicao']+'<br><br>';
    msg += '<b>Periodo de veiculação</b> '+decupagem['periodo']+' MESES<br><br>';
    msg += '<b>Território</b> '+decupagem['praca']+'<br><br>';
    msg += '<b>Mídia</b> '+decupagem['midias']+'<br><br>';
    msg += '<b>Condecine</b> '+decupagem['condecine']+'<br><br>';
    
    msg += '<b>Exclusividade de elenco:</b> '+decupagem['exclusividade']+'<br><br>';
    
    let still = 'Não';

    if(info[6]/1===1){
      still = 'Sim';
    }
    msg += '<b>Liberação para foto still:</b> '+still+'<br><br>';
    
    msg += '<b>Roteiros:</b><br>';
    msg += '<a href="'+decupagem['roteiros']+'">Click para abrir</a><br>';

    msg += '<br><b> Entregas:</b><br>';

    let entregas = this.state.entregas;
    
    for(let i=0;i<entregas.length;i++){

      msg += entregas[i]['qt']+' x '+ entregas[i]['segundos']+'s com '+entregas[i]['reducoes'] + ' Reduções e '+entregas[i]['formatos']+' Formatos de saída - '+entregas[i]['info']+'<br>';
    
    }

    msg += '<br><b> Infos de post:</b><br>';

    
    for(let i=0;i<itensPost.length;i++){
      
       
        
        if(infoPost[i]/1===1){
          msg += '<br> - '+itensPost[i];
          }
    
    }

    msg += '<br><br><b>Descrição de Pós-produção:</b><br>';
    msg += decupagem['desc_post'];

    msg += '<br><br><b>Informações Extras:</b><br>';
    msg += decupagem['infos'];
    
    if(this.state.orcarLiquor/1===0){
      api.post('insert/sendDecupagem.php?&r='+Math.random(10000),JSON.stringify({ 
        msg: msg,
        usuario_id:this.state.usuario_id,
        diretor_id:this.state.diretor_id,
        title:title,
        budget_id:this.state.id,
        urgencia:decupagem['prazo_orc']
      
      })).then(result => {
            this.endLoad();
            this.setState({
              labelBt1:'Enviado'
            })
            this.endLoad()
      })
    }else{
      
      if(this.state.liquor_active/1!==1){
      // INFORMA LIQUOR
      api.get('insert/newLiquor.php?empresa_id='+localStorage['empresa_id']+'&cliente='+this.state.cliente+'&atendimento_id='+this.state.usuario_id+'&budget_id='+this.state.id+'&nome='+this.state.projeto+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
  
            // ENVIA DECUPAGEM
            api.post('insert/sendDecupagem.php?&r='+Math.random(10000),JSON.stringify({ 
              msg: msg,
              usuario_id:this.state.usuario_id,
              diretor_id:this.state.diretor_id,
              title:title,
              budget_id:this.state.id,
              urgencia:decupagem['prazo_orc']
            
            })).then(result2 => {
                  
                  this.endLoad()
            })

        }
        
  
      }).then(result => {
        this.endLoad();
        this.setState({
          labelBt1:'Enviado'
        })
        this.endLoad()
      })
    }else{

      // ENVIA DECUPAGEM
      api.post('insert/sendDecupagem.php?&r='+Math.random(10000),JSON.stringify({ 
        msg: msg,
        usuario_id:this.state.usuario_id,
        diretor_id:this.state.diretor_id,
        title:title,
        budget_id:this.state.id,
        urgencia:decupagem['prazo_orc']
      
      })).then(result2 => {
            
        this.setState({
          labelBt1:'Enviado'
        })
        this.endLoad()
      })


    }


  }
    


   
  }
  if(w===1){

    // DIRETOR
    let title = "Decupagem | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto + ' | ' + this.state.diretor;
    let decupagem = this.state.decupagem;
    let msg = '<h1 style="font-size:1.2vw;line-height:1.3vw">Decupagem feita por '+this.state.diretor+'</h1><br><br>';

    msg += '<b>Live action:</b><br><br>';

    msg += '<b>Diárias:</b> '+decupagem['diarias']+'<br><br>';
    msg += '<b>Cidade:</b> '+decupagem['cidade']+'<br><br>';
    msg += '<b>Locações Privadas:</b> '+decupagem['locacoes_privadas']+'<br><br>';
    msg += '<b>Locações Públicas:</b> '+decupagem['locacoes_publicas']+'<br><br>';
    msg += '<b>Quadros de storyboard:</b> '+decupagem['storyboard']+'<br><br>';
    msg += '<b>Atores Principais:</b> '+decupagem['atores_principais']+'<br><br>';
    msg += '<b>Atores Coadjuvantes:</b> '+decupagem['atores_coad']+'<br><br>';
    msg += '<b>Figuração:</b> '+decupagem['figuracao']+'<br><br>';
    msg += '<b>Diretor de produção:</b> '+decupagem['diretor_producao']+'<br><br>';
    msg += '<b>Assistente de direção:</b> '+decupagem['ad']+'<br><br>';
    msg += '<b>Diretor de fotografia:</b> '+decupagem['dop']+'<br><br>';
    msg += '<b>Diretor de arte:</b> '+decupagem['diretor_arte']+'<br><br>';
    msg += '<b>Figurinista:</b> '+decupagem['figurinista']+'<br><br>';
    msg += '<b>Produtor de elenco:</b> '+decupagem['produtor_elenco']+'<br><br>';
    msg += '<b>Make-up/hair:</b> '+decupagem['make']+'<br><br>';
    msg += '<b>Culinarista:</b> '+decupagem['culinarista']+'<br><br>';
    msg += '<b>Efeitos especiais:</b> '+decupagem['fx']+'<br>';
    msg += '<b>Movimentos e solicitações extras:</b><br> '+decupagem['obs_diretor']+'<br><br>';

    msg += '<b>Edit e Post:</b><br><br>';
    msg += '<b>Editor:</b> '+decupagem['editor']+'<br><br>';
    msg += '<b>colorista:</b> '+decupagem['colorista']+'<br><br>';
    msg += '<b>Finalizador:</b> '+decupagem['finalizador']+'<br><br>';


    api.post('insert/sendDecupagem.php?&r='+Math.random(10000),JSON.stringify({ 
      msg: msg,
      usuario_id:this.state.usuario_id,
      title:title,
      diretor_id:this.state.diretor_id,
      budget_id:this.state.id,
      urgencia:decupagem['prazo_orc']
      
    
    })).then(result => {
          this.endLoad();
          this.setState({
            labelBt2:'Enviado'
          })

          this.endLoad()
    })

  }

  if(w===2){

      
    let itensPost = this.state.itensPost;
    let infoPost = this.state.infoPost;
    let decupagem = this.state.decupagem;
   
    
    let title = "Briefing de post | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto + ' | ' + this.state.diretor;
    
    let msg = '';
    
    if(this.state.diretor){
      msg += '<b>Diretor:</b> '+this.state.diretor+'<br><br>';
    }
    if(this.state.agencia){
      msg += '<b>Agência:</b> '+this.state.agencia+'<br><br>';
    }
    
    msg += '<b>Entrega do orçamento:</b> '+decupagem['prazo_orc']+'<br><br>';
    msg += '<b>Entrega do projeto:</b> '+this.formatDate2(decupagem['prazo_ent'])+'<br><br>';
    
    msg += '<b>Periodo de veiculação</b> '+decupagem['periodo']+' MESES<br><br>';
    msg += '<b>Mídia</b> '+decupagem['midias']+'<br><br>';
    msg += '<b>Território</b> '+decupagem['praca']+'<br><br>';
    
    msg += '<b>Roteiros:</b><br>';
    msg += '<a href="'+decupagem['roteiros']+'">Click para abrir</a><br>';

    msg += '<br><b> Entregas:</b><br>';

    let entregas = this.state.entregas;
    
    for(let i=0;i<entregas.length;i++){

      msg += entregas[i]['qt']+' x '+ entregas[i]['segundos']+'s com '+entregas[i]['reducoes'] + ' Reduções e '+entregas[i]['formatos']+' Formatos de saída - '+entregas[i]['info']+'<br>';
    
    }

    msg += '<br><b> Infos de post:</b><br>';

    
    for(let i=0;i<itensPost.length;i++){
      
       
        
        if(infoPost[i]/1===1){
          msg += '<br> - '+itensPost[i];
          }
    
    }
    msg += '<br><br><b>Descrição de pós-produção:</b><br>';
    msg += decupagem['desc_post'];
    msg += '<br><br><b>Informações Extras:</b><br>';
    msg += decupagem['infos'];
    
    api.post('insert/sendDecupagem.php?&r='+Math.random(10000),JSON.stringify({ 
      msg: msg,
      usuario_id:this.state.usuario_id,
      diretor_id:this.state.diretor_id,
      title:title,
      budget_id:this.state.id,
      urgencia:decupagem['prazo_orc']
      
    
    })).then(result => {
          this.endLoad();
          this.setState({
            labelBt3:'Enviado'
          })

          this.endLoad()
    })

   
  }

        

   }


// BRIEFING POST

briefingPost = () => {

}


// UPDATE
update = () => {
  this.getEntregas()
}

// ORCAR LIQUOR

swOrcarLiquor = () => {
  this.setState({
    orcarLiquor: 1-this.state.orcarLiquor
  })
}
//VOID
   void = () => {

}


  render(){

  



  return (

    
    <ThemeProvider theme={theme}>
          {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
      <Meta title={"Decupagem | "+this.state.id + ' - ' +this.state.cliente + ' ' + this.state.projeto} />
          <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
          
    <div className="conteudo">
           
          <Grid container spacing={3}>
                
                <Grid item xs={9}><div className="titulo"><div className="l color_1">{this.state.projeto!=="" ? this.state.id : ''}</div><div className="l"></div>{this.state.cliente ? ' '+this.state.cliente + ' ' + this.state.projeto + ' | ' + this.state.diretor : ''}</div></Grid>
                <Grid item xs={3} className="tr">{this.state.editNome ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onBlur={(e)=>this.setNomeVersao(e)} placeholder={this.state.versao} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={()=>this.editNomeVersao()} className="pd15 mini-titulo">{this.state.versao}</div>}</Grid>

          </Grid>

          <div className="mt20"></div>

          <Grid container spacing={0}>
              {this.state.imgs.map((img,objectId)=>(
                <Grid item xs className="imgHost"><div onClick={(w)=>this.marcaGif(objectId)} className={img/1===0 ? "imgGif gs" : "imgGif"} style={{backgroundImage: "url('http://treatments.run/landscape/img/s_"+(objectId+1)+".jpg')"}}></div></Grid>
              ))}
          </Grid>

          <div className="mt20"></div>

          <Grid container>
                
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Como chegou o orçamento?</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc">
                  <div className="">
                
                {this.state.decupagem ?
                <TextField
                  fullWidth={true}
                  
                  placeholder='Direto para o diretor, prospect recente, relacionamento..'
                  onBlur={e => {
                    this.changeDecupagem(e.target.value,'origem')
                  }}
                  onChange={e => {
                    this.upDecupagem(e.target.value,'origem')
                  }}
                  value={this.state.decupagem ? (this.state.decupagem.origem) : ''}
                  variant="outlined"
              />
              : ''}
                  
                  </div></Grid>
                  <Grid item xs={4}>
                  <Grid container spacing={0}>
                    <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Coordenador com acesso único</div></Grid>
                    <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                    {this.state.decupagem ?
                    <MySelect
                        fullWidth={true}
                        list={this.state.coordenadores}
                        placeholder=''
                        change={(e)=>this.changeCoordenador(e)}
                        initValue={this.state.coordenador}
                        variant="outlined"
                      />
                      : '' }
                  
                  </div></Grid>
                </Grid>
                  </Grid>
               
                
              </Grid> 
              
              <div className="mt10"></div>
          <Grid container spacing={1}>
          {this.state.a_live/1===0 ? this.state.itens.map((itens,objectId)=>(
              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">{itens}</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><Switch className="mt5" checked={this.state.info[objectId]/1} onChange={(w) => this.mudaSwitch(objectId,0)} id='0'/></Grid>
                </Grid>
                </Grid>
                )) : ''}
            
            </Grid>

            <div className="mt10"></div>
            <Grid container spacing={1}>
          
            
              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Target</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                  {this.state.decupagem ? 
                  <CurrencyTextField
                    fullWidth={true}
                    currencySymbol="R$"
                    outputformat="number"
                    onBlur={e => {
                      this.changeDecupagem(e.target.value,'budget')
                    }}
                    defaultValue={this.state.decupagem.budget}
                    variant="outlined"
                  /> : ''}</div>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Condicao de pagamento</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                <TextField
                    fullWidth={true}
                    placeholder='30dd'
                    
                    onBlur={e => {
                      this.changeDecupagem(e.target.value,'condicao')
                    }}
                    onChange={e => {
                      this.upDecupagem(e.target.value,'condicao')
                    }}
                    value={this.state.decupagem ? this.state.decupagem.condicao : ''}
                    variant="outlined"
                  />
                  
                  </div></Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Prazo para orçamento:</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                
             
              <MyDateTimePicker
                  onChange={e => {
                    this.changeDecupagem(e,'prazo_orc')
                  }}
                  onBlur={e => {
                    this.upDecupagem(e,'prazo_orc')
                  }}
                  view={["year", "month","date"]}  
                  format="dd/MM/YY" 
                  label="Entrega de orçamento" 
                  startDate={this.state.decupagem ? this.state.decupagem.prazo_orc : ''}
              />
                  
                  </div></Grid>

                  


                </Grid>
              </Grid>

              

              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Entrega do projeto</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                <MyDatePicker 
                onChange={(e)=>this.changeDecupagem(e,'prazo_ent')} 
                view={["year", "month","date"]}  
                format="dd/MM/yyyy" 
                startDate={this.state.decupagem ? (this.state.decupagem.prazo_ent) : ''}
              />
     
                  </div></Grid>

                  


                </Grid>
              </Grid>


              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Concorrência</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                <TextField
                    fullWidth={true}
                    placeholder=''
                    onBlur={e => {
                      this.changeDecupagem(e.target.value,'concorrencia')
                    }}
                    onChange={e => {
                      this.upDecupagem(e.target.value,'concorrencia')
                    }}
                    value={this.state.decupagem ? this.state.decupagem.concorrencia : ''}
                    variant="outlined"
                  />
                  
                  </div></Grid>

                  


                </Grid>
              </Grid>


             
               <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Condecine</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                {this.state.decupagem ?
                <MySelect
                    fullWidth={true}
                    list={this.state.condecine}
                    placeholder=''
                    change={(e)=>this.changeDecupagem(e,'condecine')}
                    initValue={this.state.decupagem.condecine}
                    variant="outlined"
                  />
                  : '' }
                  
                  </div></Grid>
                </Grid>
              </Grid> 

              
             

             
               

              
              

              {this.state.a_live/1===0 ?
               <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Período de veiculação</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                {this.state.decupagem ?
                <MySelect
                    fullWidth={true}
                    list={this.state.periodo}
                    placeholder=''
                    change={(e)=>this.changeDecupagem(e,'periodo')}
                    initValue={this.state.decupagem.periodo}
                    variant="outlined"
                  />
                  : ''}
                  
                  </div></Grid>
                </Grid>
              </Grid> 

              
              :''}

              {this.state.a_live/1===0 ?
              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Exclusividade</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><div className="">
                {this.state.decupagem ?
                <TextField
                fullWidth={true}
                
                placeholder='Segmentos'
                onBlur={e => {
                  this.changeDecupagem(e.target.value,'exclusividade')
                }}
                onChange={e => {
                  this.upDecupagem(e.target.value,'exclusividade')
                }}
                value={this.state.decupagem ? (this.state.decupagem.exclusividade) : ''}
                variant="outlined"
              />
              : ''}
                  
                  </div></Grid>
                </Grid>
              </Grid> 
              

              :''}


      {this.state.a_live/1===0 ?
              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Liberação para foto still</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><Switch className="mt5" checked={this.state.info[6]/1} onChange={(w) => this.mudaSwitch(6,0)} id='0'/></Grid>
                </Grid>
                </Grid>
               
               
               :''}



              <Grid item xs={12}>
                <Grid container spacing={0}>
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Território</div></Grid>
                <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                {this.state.decupagem ?
                <TextField
                  fullWidth={true}
                  
                  placeholder='Território de veiculação'
                  onBlur={e => {
                    this.changeDecupagem(e.target.value,'praca')
                  }}
                  onChange={e => {
                    this.upDecupagem(e.target.value,'praca')
                  }}
                  value={this.state.decupagem ? (this.state.decupagem.praca) : ''}
                  variant="outlined"
              />
              : ''}
                  
                  </div></Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={0}>
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Mídias</div></Grid>
                <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                {this.state.decupagem ?
                <TextField
                  fullWidth={true}
                  
                  placeholder='Mídias'
                  onBlur={e => {
                    this.changeDecupagem(e.target.value,'midias')
                  }}
                  onChange={e => {
                    this.upDecupagem(e.target.value,'midias')
                  }}
                  value={this.state.decupagem ? (this.state.decupagem.midias) : ''}
                  variant="outlined"
              />
              : ''}
                  
                  </div></Grid>
                </Grid>
              </Grid>

              
              
                <Grid item xs={12}>
                <Grid container spacing={0}>
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Roteiros/Deck</div></Grid>
                <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                  
                <TextField
                    fullWidth={true}
                    
                    placeholder='Cole aqui o link'
                    onBlur={e => {
                      this.changeDecupagem(e.target.value,'roteiros')
                    }}
                    onChange={e => {
                      this.upDecupagem(e.target.value,'roteiros')
                    }}
                    value={this.state.decupagem ? (this.state.decupagem.roteiros) : ''}
                    variant="outlined"
                  />
                  
                  </div></Grid>
                </Grid>
              </Grid> 

              <Grid item xs={12}>
              {this.state.a_live/1===0 || this.state.empresa_id/1===2 ?

              <div>
                <Grid container spacing={0}>
                
                  
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Entregas, reduções e formatos</div></Grid>
                


               

                <Grid item xs={8} className="bgcolor_6 br5 tc pd10">

                  {/* Lista Entrega */}
                  
                  {this.state.entregas ? this.state.entregas.map((entregas,objectId)=>( 

                    
                  <div key={objectId} className="mt5">
                    <div>
                  
                      <Grid container spacing={1}>
                      <Grid item xs={1}>
                      <TextInput 
                                sheet="entrega"
                                id={entregas.id}
                                type="number"
                                col="qt"
                                label="Qt"
                                fullWidth={true}
                                defaultValue={entregas.qt}
                                atualiza={true}
                                update={()=>this.update()}
                                
                              />
                        </Grid>
                      <Grid item xs={1}>
                        <TextInput 
                                sheet="entrega"
                                id={entregas.id}
                                type="number"
                                col="segundos"
                                label="Segundos"
                                atualiza={true}
                                update={()=>this.update()}
                                fullWidth={true}
                                defaultValue={entregas.segundos}
                                
                              />
                      </Grid>
                          
                      <Grid item xs={1}>
                        <TextInput 
                                sheet="entrega"
                                id={entregas.id}
                                type="number"
                                col="reducoes"
                                label="Reduções"
                                fullWidth={true}
                                defaultValue={entregas.reducoes}
                                atualiza={true}
                                update={()=>this.update()}
                                
                              />
                      </Grid>

                      <Grid item xs={1}>
                        <TextInput 
                                sheet="entrega"
                                id={entregas.id}
                                type="number"
                                col="formatos"
                                label="Formatos"
                                fullWidth={true}
                                atualiza={true}
                                update={()=>this.update()}
                                defaultValue={entregas.formatos}
                                
                              />
                      </Grid>

                          <Grid item xs={8}>
                          <TextInput 
                                sheet="entregas"
                                id={entregas.id}
                                col="info"
                                label="Observações"
                                fullWidth={true}
                                defaultValue={entregas.info}
                                
                              />
                          </Grid>
                        
                      </Grid>
                      
                  </div>

                  
                  <hr className="small"/>
                  </div>


                    )) : ''}


                  {/* Fim Lista Versão */}

                 
                  <div onClick={()=>this.novaEntrega()} className="bt bgcolor_8">+ Entrega</div></Grid>

                  
                </Grid>


          </div> : ''}
          </Grid>
          {this.state.decupagem ?
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Soma de cópias</div></Grid>
                      <Grid item xs={8} className="bgcolor_6 br5 tc">
                      <TextField
                            fullWidth={true}
                            
                            InputProps={{
                              readOnly: true,
                            }}
                            
                                  value={this.state.qtCopias ? (this.state.qtCopias) : ''}
                                variant="outlined"
                            />
                      </Grid>
                    </Grid>
                </Grid>
                
                : ''}

              
              
              <Grid item xs={12}>
                <Grid container spacing={0}>
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Informações extras</div></Grid>
                <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                <TextField
                    fullWidth={true}
                    multiline
                    minrows={15}
                    placeholder='Escreva..'
                    onBlur={e => {
                      this.changeDecupagem(e.target.value,'infos')
                    }}
                    onChange={e => {
                      this.upDecupagem(e.target.value,'infos')
                    }}
                    value={this.state.decupagem ? (this.state.decupagem.infos.split('<br>').join('\n')): ''}
                    variant="outlined"
                  />
                  
                  </div></Grid>

                  


                </Grid>
              </Grid> 
            

            
            </Grid>

            

           

          
          
          
          <Grid container spacing={1}>
          <Grid item xs={4}><div className="sub-titulo mt50 mb30">Post-production</div></Grid>
          <Grid item xs={4}>
            {this.state.orcarLiquor ? 
            <div>
            {this.state.estimate ? 
            <div className="sub-titulo mt50 mb30">
                Estimado <CurrencyFormat className="" value={this.state.valorLiquor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />
            </div>
            :
            <div className="sub-titulo mt50 mb30">
                Precisamos de call para estimar
            </div>
            }
            
            </div>
            :''}
            </Grid>
          <Grid item xs={4}><div className="sub-titulo mt50 tr mb30">
          <Switch className="mt5" checked={this.state.orcarLiquor} onChange={() => this.swOrcarLiquor()} id='0'/> Orçar com a Liquor
            </div></Grid>
          </Grid>


          <Grid container spacing={1}>
          {this.state.a_live/1===0 ? this.state.itensPost.map((itens,objectId)=>(
              <Grid item xs={4}>
                <Grid container spacing={0}>
                <Grid item xs={8} className="bgcolor_7 br5"><div className="mt5 pd15 br5">{itens}</div></Grid>
                <Grid item xs={4} className="bgcolor_6 br5 tc"><Switch className="mt5" checked={this.state.infoPost[objectId]/1} onChange={(w) => this.mudaSwitch(objectId,1)} id='0'/></Grid>
                </Grid>
                </Grid>
          )) : ''}

        <Grid container spacing={0}>
        
                <div className="pd5 w100">
                <Grid item xs={12} className="bgcolor_6 br5 tc">
                    <TextField
                                fullWidth={true}
                                multiline
                                minrows={15}
                                placeholder='Descreva a pós do projeto'
                                onBlur={e => {
                                  this.changeDecupagem(e.target.value,'desc_post')
                                }}
                                onChange={e => {
                                  this.upDecupagem(e.target.value,'desc_post')
                                }}
                                value={this.state.decupagem ? (this.state.decupagem.desc_post.split('<br>').join('\n')): ''}
                                variant="outlined"
                              />
              </Grid>
              </div>
          
      </Grid>

</Grid>
          <div className="mt20"></div>
          <Grid container spacing={1}>
            <Grid item xs={1}><div className="bt bgcolor_1" onClick={()=>this.sendEmail(0)}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> :  this.state.labelBt1 }</div></Grid>
           
            <Grid item xs={2}>
            <div className="bt bgcolor_1" onClick={()=>this.sendEmail(2)}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> :  this.state.labelBt3 }</div></Grid>
         
            <Grid item xs={2}>
                <Tooltip title="Planilha" placement="top" arrow TransitionComponent={Zoom}><IconButton href={"/sheet/"+this.state.id}><ReorderIcon/></IconButton></Tooltip>
                <Tooltip title="Cronograma" placement="top" arrow TransitionComponent={Zoom}><IconButton href={'../calendar/'+this.state.id}><CalendarToday/></IconButton></Tooltip>
                <Tooltip title="Carta orรงamento" placement="top" arrow TransitionComponent={Zoom}><IconButton href={'../office'+this.state.office+'/'+this.state.id}><InsertDriveFile/></IconButton></Tooltip>
          </Grid>
          </Grid>

          
          


          {this.state.a_live/1===0 ?

          <div>

            
          <Grid container spacing={3}>
                
                <Grid item xs={9}><div className="titulo mt50"><div className="l">Decupagem do diretor</div></div></Grid>
                

          </Grid>



          <div className="mt20"></div>

                    
    <Grid container spacing={1}>


      {/* Selects diretor */}
      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Diárias</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">
            {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'0','value':0},{'label':'1','value':1},{'label':'2','value':2},{'label':'3','value':3},{'label':'4','value':4},{'label':'5','value':5},{'label':'6','value':6},{'label':'7','value':7},{'label':'8','value':8},{'label':'9','value':9},{'label':'10','value':10}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'diarias')}
            
              initValue={this.state.decupagem.diarias}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={0}>
          <Grid item xs={2} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Cidade</div></Grid>
          <Grid item xs={10} className="bgcolor_6 br5 tc">
            {this.state.decupagem ? 
            <TextInput 
            autocomplete={false}
            sheet="decupagem"
            id={this.state.decupagem.id}
            col="cidade"
            
            fullWidth={true}
            defaultValue={ this.state.decupagem.cidade}
            
          />
            :''}

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Locações privadas</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'0','value':0},{'label':'1','value':1},{'label':'2','value':2},{'label':'3','value':3},{'label':'4','value':4},{'label':'5','value':5},{'label':'6','value':6},{'label':'7','value':7},{'label':'8','value':8},{'label':'9','value':9},{'label':'10','value':10}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'locacoes_privadas')}
            
              initValue={this.state.decupagem.locacoes_privadas}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Locações públicas</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'0','value':0},{'label':'1','value':1},{'label':'2','value':2},{'label':'3','value':3},{'label':'4','value':4},{'label':'5','value':5},{'label':'6','value':6},{'label':'7','value':7},{'label':'8','value':8},{'label':'9','value':9},{'label':'10','value':10}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'locacoes_publicas')}
            
              initValue={this.state.decupagem.locacoes_publicas}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Quadros Storyboard</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'10','value':10},{'label':'20','value':20},{'label':'30','value':30},{'label':'40','value':40},{'label':'50','value':50},{'label':'60','value':60},{'label':'80','value':80},{'label':'100','value':100},{'label':'150','value':150},{'label':'200','value':200},{'label':'250','value':250}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'storyboard')}
            
              initValue={this.state.decupagem.storyboard}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Atores principais</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'0','value':0},{'label':'1','value':1},{'label':'2','value':2},{'label':'3','value':3},{'label':'4','value':4},{'label':'5','value':5},{'label':'6','value':6},{'label':'7','value':7},{'label':'8','value':8},{'label':'9','value':9},{'label':'10','value':10}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'atores_principais')}
            
              initValue={this.state.decupagem.atores_principais}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Atores coadjuvantes</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'0','value':0},{'label':'1','value':1},{'label':'2','value':2},{'label':'3','value':3},{'label':'4','value':4},{'label':'5','value':5},{'label':'6','value':6},{'label':'7','value':7},{'label':'8','value':8},{'label':'9','value':9},{'label':'10','value':10}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'atores_coad')}
            
              initValue={this.state.decupagem.atores_coad}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Figuração</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          {this.state.decupagem ? 
            <MySelect
              fullWidth={true}
              list={[{'label':'0','value':0},{'label':'5','value':5},{'label':'10','value':10},{'label':'15','value':15},{'label':'20','value':20},{'label':'30','value':30},{'label':'40','value':40},{'label':'50','value':50},{'label':'60','value':60},{'label':'70','value':70},{'label':'100','value':100},{'label':'200','value':200}]}
              placeholder=''
              change={(e)=>this.changeDecupagem(e,'figuracao')}
            
              initValue={this.state.decupagem.figuracao}
              variant="outlined"
            />
            :''}

          </Grid>
        </Grid>
      </Grid>
      
      {/* Team diretor */}

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Diretor de produção</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="produtor" label={this.state.decupagem ? (this.state.decupagem.diretor_producao) : ''} blur={(e)=>this.changeDecupagem(e,'diretor_producao')} change={(e)=>this.changeDecupagem(e.target.value,'diretor_producao')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Assistente de direção</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="ad" label={this.state.decupagem ? (this.state.decupagem.ad) : ''} blur={(e)=>this.changeDecupagem(e,'ad')} change={(e)=>this.changeDecupagem(e.target.innerText,'ad')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">DOP</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="dop" label={this.state.decupagem ? (this.state.decupagem.dop) : ''} blur={(e)=>this.changeDecupagem(e,'dop')} change={(e)=>this.changeDecupagem(e.target.innerText,'dop')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Diretor de arte</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="arte" label={this.state.decupagem ? (this.state.decupagem.diretor_arte) : ''} blur={(e)=>this.changeDecupagem(e,'diretor_arte')} change={(e)=>this.changeDecupagem(e.target.innerText,'diretor_arte')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Figurinista</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="figurinista" label={this.state.decupagem ? (this.state.decupagem.figurinista) : ''} blur={(e)=>this.changeDecupagem(e,'figurinista')} change={(e)=>this.changeDecupagem(e.target.innerText,'figurinista')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Produtor de elenco</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="elenco" label={this.state.decupagem ? (this.state.decupagem.produtor_elenco) : ''} blur={(e)=>this.changeDecupagem(e,'produtor_elenco')} change={(e)=>this.changeDecupagem(e.target.innerText,'produtor_elenco')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Make-up / Hair</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="make" label={this.state.decupagem ? (this.state.decupagem.make) : ''} blur={(e)=>this.changeDecupagem(e,'make')} change={(e)=>this.changeDecupagem(e.target.innerText,'make')}/>

          </Grid>
        </Grid>
      </Grid>

      

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Culinarista</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="culinarista" label={this.state.decupagem ? (this.state.decupagem.culinarista) : ''} blur={(e)=>this.changeDecupagem(e,'culinarista')} change={(e)=>this.changeDecupagem(e.target.innerText,'culinarista')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Efeitos especiais</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="fx" label={this.state.decupagem ? (this.state.decupagem.fx) : ''} blur={(e)=>this.changeDecupagem(e,'fx')} change={(e)=>this.changeDecupagem(e.target.innerText,'fx')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
                <Grid container spacing={0}>
                <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Movimentos, efeitos ou solicitações extras</div></Grid>
                <Grid item xs={8} className="bgcolor_6 br5 tc"><div className="">
                <TextField
                    fullWidth={true}
                    multiline
                    minrows={10}
                    placeholder='Descreva'
                    onBlur={e => {
                      this.changeDecupagem(e.target.value,'obs_diretor')
                    }}
                   
                    defaultValue={this.state.decupagem ? (this.state.decupagem.obs_diretor) : ''}
                    variant="outlined"
                  />
                  
                  </div></Grid>

                  


                </Grid>
              </Grid> 
      <div className="mt20 mb10 pd5 mini-titulo w100">Post</div>
    
      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Editor</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="editor" label={this.state.decupagem ? (this.state.decupagem.editor) : ''}  blur={(e)=>this.changeDecupagem(e,'editor')} change={(e)=>this.changeDecupagem(e.target.innerText,'editor')}/>

          </Grid>
        </Grid>
      </Grid>

      

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Colorista</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="colorista" label={this.state.decupagem ? (this.state.decupagem.colorista) : ''}  blur={(e)=>this.changeDecupagem(e,'colorista')} change={(e)=>this.changeDecupagem(e.target.innerText,'colorista')}/>

          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={0}>
          <Grid item xs={4} className="bgcolor_7 br5"><div className="mt5 pd15 br5">Finalizador /Post House</div></Grid>
          <Grid item xs={8} className="bgcolor_6 br5 tc">

          <AutoCompFree sheet="users" tipo="finalizador" label={this.state.decupagem ? (this.state.decupagem.finalizador) : ''}  blur={(e)=>this.changeDecupagem(e,'finalizador')} change={(e)=>this.changeDecupagem(e.target.innerText,'finalizador')}/>

          </Grid>
        </Grid>
      </Grid>


  
  </Grid>



  

          <Grid container spacing={1}>
            <Grid item xs={1}><div className="bt bgcolor_1 mt20" onClick={()=>this.sendEmail(1)}>{this.state.loading ? <CircularProgress size={20} color="secondary"/> : this.state.labelBt2}</div></Grid>
            
          </Grid>
          </div> : ''}
          </div>

        
        
    </ThemeProvider>
  )
}

}
  
 