import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import {TextInput,MySwitch} from '../../components/textField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';



const theme = createTheme (
    {
        palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
    },

    }
)


export default class ListClientes extends Component {


  constructor(props){
      super();
    
      this.state = {
            userid:props.id,
            token: localStorage.getItem("tokens"),
            empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
            confirmado:props.confirmado/1,
            loading:true,
            loadPerc:0,
            listSkill:[],
            listSkillGet:false,
            listUser:[],
            optionUsers: [],
            selected:0,
            permissao: [{"id":"0","label":"Admin"},{"id":"1","label":"Full"},{"id":"2","label":"Budget"},{"id":"3","label":"Artist"}],
            overhead: [{"id":"0","label":"Overhead"},{"id":"1","label":"Horas Vendáveis"}],
            contrato: [{"id":"0","label":"PJ"},{"id":"1","label":"CLT"}],
            classes: ({
            root: {
                flexGrow: 0,   
            },
            paper: {
                textAlign: 'center',
                color: theme.palette.text.secondary,
                padding: 0,
                margin: -2,   
            },
            control: {
                padding: 10,
                marginTop: 5,
                background: "#fc3"
            }
            })
        }
     
  }
  

 

  
  
  async componentDidMount(){
 
  this.getSkills()
  this.getUser()
    
  }

  // LISTA DOS SKILLS

  getSkills = async () => {
    this.toLoad()
    
    api.get('get/getSelect.php?sheet=skill&id='+this.state.empresa_id+'&col_id=empresa_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({
              listSkill: result.data,
              listSkillGet:true
            })
          
          this.endLoad()
          
      }});

  }


  // LISTA DO USER

  getUser = async () => {

    
    this.toLoad()
   
    api.get('get/get.php?sheet=users&id='+this.state.userid+'&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listUser: result.data})
          this.endLoad()
          
      }});

  }



  

  // TOLOAD

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
  

  endLoad = () => {
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () => {
    if(this.state.loadPerc<100){
            this.setState({
                loadPerc:this.state.loadPerc*1.4,
            })
        }
    }


  
   

  render(props){

    const classes = this.state.classes;


  return (

    
    <ThemeProvider theme={theme}>
        <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
      {this.state.listUser.map((user,objectID)=>(

      <div key={objectID} className={classes.root}>
          <Grid container spacing={3}>
          
            
            
            <Grid item xs={12}>

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <div className="mb20  mt20">
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="nome"
                            type="text"
                            label="Nome"
                            fullWidth="true"
                            defaultValue={user.nome}
                        />
                    </div>

                    <div className="mb20">
                        <TextInput 
                            kind="email"
                            id={user.id}
                            sheet="users"
                            col="email"
                            type="text" 
                            label="E-Mail"
                            fullWidth="true"
                            defaultValue={user.email}
                        />
                    </div>

                    

                

                </Grid>

                <Grid item xs={3}>
                    <div className="mb20  mt20">
                        <TextInput 
                            kind="nome"
                            id={user.id}
                            sheet="users"
                            col="razao_social"
                            type="text" 
                            label="Razão Social"
                            fullWidth="true"
                            defaultValue={user.razao_social}
                        />
                    </div>

                    <div className="mb20">
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="endereco"
                            type="text" 
                            label="Endereço"
                            fullWidth="true"
                            defaultValue={user.endereco}
                        />
                    </div>

                   
                    

                </Grid>

                <Grid item xs={3}>
                    
                    <div className="mb20  mt20">
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="inscricao_estadual"
                            type="numbers" 
                            label="Inscrição Estadual"
                            fullWidth="true"
                            defaultValue={user.cpf}
                        />
                    </div>

                    <div className="mb20">
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="cidade"
                            type="text" 
                            label="Cidade"
                            fullWidth="true"
                            defaultValue={user.cidade}
                        />
                    </div>

                   

                   

                    
                </Grid>

                <Grid item xs={3}>
                    
                    
                    <div className="mb20  mt20">
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            cnpj={true} 
                            col="cnpj"
                            type="numbers" 
                            label="CNPJ"
                            fullWidth="true"
                            defaultValue={user.cnpj}
                        />
                    </div>

                    <div className="mb20  mt20">
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="pais"
                            type="text" 
                            label="País"
                            fullWidth="true"
                            defaultValue={user.pais}
                        />
                    </div>

                    
                   

                    
                    <div className="mt30">

                    <MySwitch checked={user.confirmado} onChange={this.handleChange} nome="Confirmado" id={user.id} sheet="users" col="confirmado" />

                    </div>


                    
                    
                </Grid>
                
                </Grid>
           

            </Grid>
  
            
         
            </Grid>


      </div>
      ))}

    </ThemeProvider>

  )
}

}